@font-face {
  font-family: "Permanent Marker";
  src: local("Permanent Marker"),
    url(../assets/fonts/PermanentMarker-Regular.ttf);
}
@import "./theme.scss";
// https://eduardoboucas.github.io/include-media/
@import "~include-media/dist/_include-media.scss";
// https://create-react-app.dev/docs/adding-bootstrap
@import "~bootstrap/scss/bootstrap";

/* General
************************************************************** */
body {
  background-color: black;
}
h2 {
  font-family: "Permanent Marker";
  font-size: 4rem;
}
a:hover {
  text-decoration: none;
  cursor: pointer;
}
hr {
  border: 0.1rem solid $reactBlue;
  background-color: $reactBlue;
  margin: 2rem auto 2rem auto;
}
.social-links {
  font-size: 2.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.social-link {
  margin-left: 1%;
  margin-right: 1%;
}
#error {
  color: $reactBlue;
}
.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.navbar {
  height: 10%;
  display: flex;

  @media (max-width: 768px) {
    display: none;
  }
}
.navbar-item {
  margin-left: 10%;
}
.experience-card {
  background-color: #343a40;
}

/* Theme
************************************************************** */
.dark {
  @include darkTheme();
}
.light {
  @include lightTheme();
}

/* About
************************************************************** */
#about {
  button {
    background-color: $reactBlue;
  }
  .nav-item {
    .active {
      color: $reactBlue !important;
    }
  }
  h1 {
    font-family: "Permanent Marker";
    font-size: 5rem;
  }
  p {
    font-size: 1.5rem;
  }
  .scroll {
    color: $reactBlue;
    #scroll-down {
      margin-top: 5rem;
    }
  }
  img {
    margin-top: 1rem;
    border: 0.2rem solid $reactBlue;
    border-radius: 50%;
    width: 30%;
    height: auto;
  }
}

/* Skills
************************************************************** */
#skills {
  figure {
    margin-top: 1.5rem;
    figcaption {
      margin-top: 0.5rem;
      font-size: 1.5rem;
    }
  }
}

/* Projects
************************************************************** */
#projects {
  .card {
    border: 2px solid $reactBlue;
    background-color: #292b2c;
    img {
      padding: 1rem;
      max-height: 15rem;
    }
    .card-title {
      text-decoration: underline;
      text-decoration-color: $reactBlue;
    }
  }
}

/* Contact
************************************************************** */
#contact {
  h2 {
    margin-top: 3rem;
  }
  img,
  h3 {
    margin-top: 1rem;
  }
  min-height: 90vh;
  img {
    border: 0.2rem solid $reactBlue;
    border-radius: 50%;
    width: 60%;
    height: auto;
  }
  span {
    color: $reactBlue;
  }
  .scroll {
    color: $reactBlue;
    #scroll-up {
      margin: 5rem 0 1rem 0;
    }
  }
}

/* Footer
************************************************************** */
footer {
  a {
    color: white;
  }
  span {
    color: red;
  }
  #myInfo {
    color: $reactBlue;
  }
}

/* Media Queries
************************************************************** */
// iPhones
@include media(">=phone", "<tablet") {
  h2 {
    font-size: 2.75rem;
  }
  hr {
    margin: 0.5rem auto 0.5rem auto;
  }
  .scroll,
  .social-links {
    font-size: 2rem;
  }
  .app-error {
    h1 {
      font-size: 1.5rem;
    }
  }
  #about {
    h1 {
      font-size: 2.75rem;
    }
    p {
      font-size: 1rem;
    }
    .scroll {
      #scroll-down {
        margin-top: 2.5rem;
      }
    }
  }
  #skills {
    figure {
      margin-top: 0.75rem;
      figcaption {
        margin-top: 0.25rem;
        font-size: 1rem;
      }
    }
  }
  #projects {
    h2 {
      margin-top: 4.25rem;
    }
  }
  #contact {
    h2 {
      margin-top: 3.25rem;
    }
    img {
      width: 45%;
    }
    .scroll {
      #scroll-up {
        margin-top: 3rem;
      }
    }
  }
}
// iPhones landscape
@include media(">=phone", "<tablet", "landscape") {
  #about {
    h1 {
      margin-top: 2.5rem;
    }
    .scroll {
      #scroll-down {
        margin-top: 0.5rem;
      }
    }
  }
  #skills {
    h2 {
      margin-top: 4.75rem;
    }
  }
  #contact {
    h2 {
      margin-top: 3.8rem;
    }
    img {
      width: 32%;
      margin-top: 0;
    }
    .scroll {
      #scroll-up {
        margin: 2rem 0 2rem 0;
      }
    }
  }
}
// iPad
@include media(">=tablet", "<desktop") {
  #about {
    h1 {
      font-size: 4rem;
    }
  }
  #contact {
    img {
      width: 35%;
    }
    .scroll {
      #scroll-up {
        margin-top: 6rem;
      }
    }
  }
}
// iPhone X landscape
@include media(">=tablet", "<desktop", "landscape") {
  h2 {
    font-size: 2.75rem;
  }
  hr {
    margin: 0.5rem auto 0.5rem auto;
  }
  .scroll,
  .social-links {
    font-size: 2rem;
  }
  .app-error {
    h1 {
      font-size: 1.5rem;
    }
  }
  #about {
    h1 {
      font-size: 2.75rem;
      margin-top: 1.5rem;
    }
    p {
      font-size: 1rem;
    }
    .scroll {
      #scroll-down {
        margin-top: 2.5rem;
      }
    }
  }
  #skills {
    h2 {
      margin-top: 4.75rem;
    }
    figure {
      margin-top: 0.75rem;
      figcaption {
        margin-top: 0.25rem;
        font-size: 1rem;
      }
    }
  }
  #projects {
    h2 {
      margin-top: 4.25rem;
    }
  }
  #contact {
    h2 {
      margin-top: 3.5rem;
    }
    img {
      width: 33%;
      margin-top: 0;
    }
    .scroll {
      #scroll-up {
        margin: 2rem 0 2rem 0;
      }
    }
  }
}
// iPad Landscape
@include media(">tablet", "<=desktop", "landscape") {
  hr {
    margin: 0.5rem auto 0.5rem auto;
  }
  #about {
    h1 {
      font-size: 4rem;
    }
    .scroll {
      #scroll-down {
        margin-top: 1rem;
      }
    }
  }
  #contact {
    .scroll {
      #scroll-up {
        margin-top: 5rem;
      }
    }
  }
}
